import React from "react";
import simgame from "../img/home-offers/augmented-reality.png";
//import Mailchimp fom 'react-mailchimp-form'
import {Link} from "react-router-dom";

const Company = () => {
  return (
    <div id="o-wrapper" className="wrapper overflow-hidden">
      <section id="heroAbout" className="hero hero--full video-section">
        <div className="video-wrapper">
          <video
            className="video-bg"
            autoPlay
            loop
            muted
            playsInline
            poster="../img/about-page/bg-poster.jpg"
          >
            <source src={require("../video/about-bg-video.mp4").default} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="container about-header">
          <div className="hero__content">
            <h1 className="title title--uppercase">
              <span className="title__preheader title__preheader--tag">
                Company
              </span>
              ABOUT TECSLA INC{" "}
            </h1>
            <p className="hero__lead">
              As an Information Technology and Digital Oriented enterprise, we
              offer 'technology solutions for learning organizations' (Tecsla);
              powered by the latest technologies.
              <p className="hero__lead">
                Why Mauritius Island? A strategic location for our international
                clients to benefit from low-operations-costs and a qualified and
                experienced labour force.
              </p>
            </p>
          </div>
        </div>
      </section>

      <div id="statsAbout" className="stats stats--blue stats--overlay">
        <div className="container">
          <div className="stats__wrapper">
            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  10
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  Years in operation
                </h3>
              </div>
            </div>

            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  10
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  Fortune 500 Clients
                </h3>
              </div>
            </div>

            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  100
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  Full-time Employees
                </h3>
              </div>
            </div>

            <div className="stats__item stats__item--4">
              <div className="stats__content stats__content--vertically">
                <p className="stats__number stats__number--inline stats__number--large">
                  3
                </p>
                <h3 className="stats__heading stats__heading--inline">
                  Offices
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="map" className="map-scroll">
        <div className="container mission-vision">
          <div className="grid grid--column grid--center">
            <h2 className="title title--black title--medium title--uppercase">
              Mission & Vision
            </h2>
            <p className="text text--w-large text--s-small">
              At Tecsla Inc., We pride ourselves as a digital Oriented
              enterprise in engineering technology solution to other enterprise
              to Create ideas that will drive corporate vision and goals thus;
              plying a marquee for a better competitive atmosphere; And while
              working, consider it done
            </p>
          </div>
        </div>
        <section
          id=""
          className=" cemetery__title--center cemetery cemetery-about panel hide"
        >
          <div className="container">
            <div className="cemetery__title cemetery__title--center">
              <h2>Our clients</h2>
              <p className="description">
                Since 2016, Tescla has constantly provides and collaborating
                with established and fortune 500 brands to drive corporate
                vision and goals through software and application development.
              </p>
            </div>
            <div id="cemetery" className="owl-carousel owl-theme">
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/universal.svg").default}
                />

                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/griffins.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/hoosbaa.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/nestle.svg").default}
                />
              </div>
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/nhs.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/land-rover.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/af-gruppen.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/clinique.svg").default}
                />
              </div>
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/guinness.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/jaguar.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/wynn.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/harley.svg").default}
                />
              </div>
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/eurostar.svg").default}
                />

                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/walt-disney.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/melco.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/gulls.svg").default}
                />
              </div>
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/ey.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/guest-services.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/euro-accident.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/audi.svg").default}
                />
              </div>
              <div className="item">
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/kjk.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/cirrus.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/bombardier.svg").default}
                />
                <div
                  className="cemetery__image owl-lazy"
                  data-src={require("../img/clients-logo/giant.svg").default}
                />
              </div>
            </div>
            <Link className="all-link all-link-about" to="/contact">
              Let’s discuss your next project <i className="isoi-angle-right" />
            </Link>
          </div>
        </section>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="hexagon" viewBox="0 0 184 161">
          <polygon points="184 80.5 138 161 46 161 0 80.5 46 0 138 0" />
          <path d="M6,168.5 L30.5,153.5 L6,168.5 Z" />
        </symbol>
      </svg>

      <section className="enterprise-core-mob">
        <div id="particles" />
        <div className="divider-t-50">
          <div className="container">
            <div className="grid grid--column grid--center">
              <h2 className="title title--black title--medium title--uppercase">
                Technology excellence
              </h2>
            </div>
          </div>
        </div>
        <div className="tech-container">
          <div className="tech-item main">
            <svg
              className="tech-triangle up"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="40"
              viewBox="0 0 276 40"
            >
              <polygon
                fill="#1476F2"
                fillRule="evenodd"
                points="276 40 0 40 134.979 0"
              />
            </svg>
            <input type="checkbox" className="read-more-state" id="post-0" />
            <div className="read-more-wrap">
              <h3>ENTERPRISE CORE</h3>
              <p>
                We offer comprehensive tech expertise and enterprise IT support.
                Our software development services include strategy consulting,
                CX design, engineering, and lifecycle management of custom
                products, as well as system integration.
              </p>
            </div>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="40"
              viewBox="0 0 276 40"
            >
              <polygon
                fill="#1476F2"
                fillRule="evenodd"
                points="276 0 134.979 40 0 0"
              />
            </svg>
            <label htmlFor="post-0" className="read-more-trigger main" />
            <div className="bg-cube" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img
                src={require("../img/home-offers/blockchain-technology.png").default}
                alt="Blockchain"
              />
              <figcaption>
                <h3>
                  <a>Blockchain</a>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-1" />
            <ul className="item-list read-more-wrap">
              <li>Private and public blockchain fork</li>
              <li>Cryptocurrencies forks, wallets and exchange</li>
              <li>disseminated smart control </li>
              <li>Cryptocurrencies, wallets, and exchanges</li>
              <li>Interconnected transaction platform</li>
              {/*<li>*/}
              {/*  <a href="#">*/}
              {/*    learn more*/}
              {/*    <i className="isoi-angle-right" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="42"
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-1" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img
                src={require("../img/home-offers/artificial-intelligence.png").default}
                alt="XR"
              />
              <figcaption>
                <h3>
                  <a>XR</a>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-2" />
            <ul className="item-list read-more-wrap">
              <li>Integrated AR/VR solution</li>
              <li>3D/4D modeling</li>
              <li>Industrial standard</li>
              <li>Installations and training</li>
              {/*<li>*/}
              {/*  <a to="https://ar.Tecsla.net/">*/}
              {/*    learn more*/}
              {/*    <i className="isoi-angle-right" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="42"
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-2" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={require("../img/home-offers/page.svg").default} alt="UX" />
              <figcaption>
                <h3>
                  <a>UX</a>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-3" />
            <ul className="item-list read-more-wrap">
              <li>UI / Front-end design</li>
              <li>CX and digital immersion</li>
              <li>solid programing for mobile platform</li>
              <li>Software management and analytics</li>
              {/*<li>*/}
              {/*  <a to="/services/ui-ux-design-services">*/}
              {/*    learn more*/}
              {/*    <i className="isoi-angle-right" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="42"
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-3" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img
                src={require("../img/home-offers/artificial-intelligence.svg").default}
                alt="AI"
              />
              <figcaption>
                <h3>
                  <a>AI</a>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-4" />
            <ul className="item-list read-more-wrap">
              <li>Cloud based AI models</li>
              <li>Machine learning algorithms</li>
              <li>Data management and analysis</li>
              <li>Visual information processing</li>
              {/*<li>*/}
              {/*  <a to="#">*/}
              {/*    learn more*/}
              {/*    <i className="isoi-angle-right" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="42"
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-4" className="read-more-trigger" />
          </div>
          <div className="tech-item">
            <figure className="hex-media">
              <img src={require("../img/home-offers/internet-of-things.png").default} alt="IoT" />
              <figcaption>
                <h3>
                  <a>IoT</a>
                </h3>
              </figcaption>
            </figure>
            <input type="checkbox" className="read-more-state" id="post-5" />
            <ul className="item-list read-more-wrap">
              <li>Unified smart applications</li>
              <li>Deplorable sensor devices </li>
              <li>Remote monitoring software</li>
              <li>Embedded platforms and management</li>
              {/*<li>*/}
              {/*  <a to="#">*/}
              {/*    learn more*/}
              {/*    <i className="isoi-angle-right" />*/}
              {/*  </a>*/}
              {/*</li>*/}
            </ul>
            <svg
              className="tech-triangle"
              xmlns="http://www.w3.org/2000/svg"
              width="276"
              height="42"
              viewBox="0 0 276 42"
            >
              <path
                fill="#FFF"
                fillRule="evenodd"
                stroke="#AAC7ED"
                d="M276 1L138 41 0 1"
              />
            </svg>
            <label htmlFor="post-5" className="read-more-trigger" />
          </div>
        </div>
      </section>

      <section className="enterprise-core">
        <div id="particles" />
        <div className="divider-t-50">
          <div className="container">
            <div className="grid grid--column grid--center">
              <h2 className="title title--black title--medium title--uppercase">
                Technology excellence
              </h2>
            </div>
          </div>
        </div>
        <div className="hexa-container">
          <div className="item item-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" stroke="#AAC7ED">
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M114,233.538767 L114,201" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img
                src={require("../img/home-offers/blockchain-technology.png").default}
                alt="Blockchain"
              />
              <figcaption>
                <h3>
                  <a> Blockchain <i className="isoi-angle-right" />
                  </a>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-top">
              <li>Private and public blockchain forks</li>
              <li>Cryptocurrencies forks, wallets and exchange</li>
              <li>Disseminated smart control </li>
              <li>Interconnected transaction platform</li>
            </ul>
          </div>
          <div className="item item-right-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" stroke="#AAC7ED">
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M6,168.5 L30.5,153.5 L6,168.5 Z" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={simgame} alt="XR" />
              <figcaption>
                <h3>
                  <a>
                    XR
                    <i className="isoi-angle-right" />
                  </a>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-top-right">
              <li>Integrated AR/VR solution</li>
              <li>3D/4D modeling</li>
              <li>Industrial standard</li>
              <li>Installations and training</li>
            </ul>
          </div>
          <div className="item item-right-bottom">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" stroke="#AAC7ED">
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path
                    d="M26.5,52.5 L3,38"
                    transform="translate(15.000000, 45.500000) scale(-1, -1) translate(-15.000000, -45.500000)"
                  />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={require("../img/home-offers/page.svg").default} alt="UX" />
              <figcaption>
                <h3>
                  <a>
                    <i className="isoi-angle-right" />
                    UX
                  </a>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-right">
              <li>UI / Front-end design</li>
              <li>CX and digital immersion</li>
              <li>solid programing for mobile platform</li>
              <li>Software management and analytics</li>
            </ul>
          </div>
          <div className="item item-left-bottom">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" stroke="#AAC7ED">
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path
                    d="M226.5,52.5 L202.5,38"
                    transform="translate(214.500000, 45.500000) scale(1, -1) translate(-214.500000, -45.500000) "
                  />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img
                src={require("../img/home-offers/artificial-intelligence.png").default}
                alt="AI"
              />
              <figcaption>
                <h3>
                  <a>
                    AI
                    <i className="isoi-angle-right" />
                  </a>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-left">
              <li>Deep learning algorithms and Machine</li>
              <li>Cloud based AI models</li>
              <li>Data analysis and management</li>
              <li>Visual information processing</li>
            </ul>
          </div>
          <div className="item item-left-top">
            <svg className="hex hex-outer" viewBox="0 0 232 203">
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 1.000000)" stroke="#AAC7ED">
                  <polygon points="230 100.5 172.5 201 57.5 201 0 100.5 57.5 0 172.5 0" />
                  <path d="M224.5,168.5 L199.5,153.5" />
                </g>
              </g>
            </svg>
            <svg className="hex hex-inner">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media">
              <img src={require("../img/home-offers/internet-of-things.png").default} alt="IoT" />
              <figcaption>
                <h3>
                  <a>
                    IoT
                    <i className="isoi-angle-right" />
                  </a>
                </h3>
              </figcaption>
            </figure>
            <ul className="item-list item-list-bottom-left">
              <li>Unified smart applications</li>
              <li>Deplorable sensor devices </li>
              <li>Remote monitoring software</li>
              <li>Embedded platforms and management</li>
            </ul>
          </div>
          <div className="item item-center">
            <svg className="hex">
              <use xlinkHref="#hexagon" />
            </svg>
            <figure className="hex-media hex-media-center">
              <figcaption>
                <h3>ENTERPRISE CORE</h3>
                <p>
                  We offer comprehensive tech expertise and enterprise IT
                  support. Our software development services include strategy
                  consulting, CX design, engineering, and lifecycle management
                  of custom products, as well as system integration.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </section>

      {/*<div className="divider-t-70 about-news">*/}
      {/*  <div className="container">*/}
      {/*    <div className="grid grid--column grid--center">*/}
      {/*      <h2 className="title title--black title--medium title--uppercase">*/}
      {/*        NEWS ON TECSLA*/}
      {/*      </h2>*/}
      {/*      <p className="text text--w-large text--s-small">*/}
      {/*        Stay in touch with our latest projects, collaborations,*/}
      {/*        appointments, events, and other industry updates.*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="posts-container divider-b-75 about-news">*/}
      {/*  <div className="container">*/}
      {/*    <div id="newsPosts" />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="ranking">*/}
      {/*  <div className="container">*/}
      {/*    <h2 className="ranking__title">Awards & Recognition</h2>*/}
      {/*    <div className="owl-carousel" id="ranking">*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/clutch-global-2018.png").default}*/}
      {/*          alt="Clutch Global 2018"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/iaop.png").default}*/}
      {/*          alt="IAOP"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/inc-5000.png").default}*/}
      {/*          alt="Inc 5000"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/stevies.png").default}*/}
      {/*          alt="The Stevies"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/top-design.png").default}*/}
      {/*          alt="Top Design Firms"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/goodfirms-software.png").default}*/}
      {/*          alt="Goodfirms Software"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/clutch-blockchain.png").default}*/}
      {/*          alt="Clutch Blockchain"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/techies.png").default}*/}
      {/*          alt="Techies"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/itfirms.png").default}*/}
      {/*          alt="IT Firms"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/business-apps.png").default}*/}
      {/*          alt="Business of Apps"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/goodfirms.png").default}*/}
      {/*          alt="Goodfirms"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/clutch-2017.png").default}*/}
      {/*          alt="Clutch 2017"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/appindex.png").default}*/}
      {/*          alt="Appindex"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="ranking__item item">*/}
      {/*        <img*/}
      {/*          className="ranking__img"*/}
      {/*          src={require("../img/ratings-logos/ibm.png").default}*/}
      {/*          alt="IBM Essentials"*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <section className="service-cta service-cta--about">
        <div className="service-cta__text-area service-cta__text-area--it-consulting">
          <p className="service-cta__white">
            We will be delighted to help you build a first-hand technology
            solution that will respond rapidly to your technological needs
            efficiently
          </p>
          <Link className="get-touch btn btn--about-news" to="/contact">
            Talk to us
          </Link>
        </div>
      </section>

      <div className="divider-t-70 divider-b-80 our-work-wrapper">
        <div className="our-work">
          <div className="our-work__item">
            <h3>OUR CLIENTS</h3>
            <p>
              See how we helped our clients succeed in Digital Transformation.
            </p>
            {/*<a to="cases">*/}
            {/*  learn more*/}
            {/*  <i className="isoi-angle-right" />*/}
            {/*</a>*/}
          </div>
          <div className="our-work__item">
            <h3>OUR TEAM</h3>
            <p>
              Meet the leadership and talents propelling Tecsla’s
              progress.
            </p>
            {/*<a to="team">*/}
            {/*  learn more*/}
            {/*  <i className="isoi-angle-right" />*/}
            {/*</a>*/}
          </div>
          <div className="our-work__item">
            <h3>OUR CAREERS</h3>
            <p>
              Explore current career opportunities in our offices around the
              world.
            </p>
            {/*<a to="careers">*/}
            {/*  learn more*/}
            {/*  <i className="isoi-angle-right" />*/}
            {/*</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;
