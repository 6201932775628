import React, { Component } from 'react';
import {TabView,TabPanel} from 'primereact/tabview';
import {Link} from 'react-router-dom';

import '../css/index.css';

export  default  class  Offers extends  React.Component {
    render() {
        return (
            <div id="o-wrapper" className="wrapper overflow-hidden">
                <section className="section-offers">
                    <div
                        id="particles"
                        data-1500="background-position:0px 0px;"
                        data-2300="background-position:0px 360px;">
                    </div>
                    <div className="container">
                        <div className="offers">
                            {/*// <!--  Section title  -->*/}
                            <div className="offers-title">
                                <h2 className="title title--black"> What We Offer </h2>
                            </div>
                            <TabView>
                                <TabPanel header="Solutions" headerClassName="offers-tablinks " leftIcon="pi pi-calendar">
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/blockchain-technology.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Blockchain Development </h3>
                                                <p className="offer-content-description">
                                                    Introduce the highest level of security and automate your operations with our
                                                    blockchain solutions. </p>
                                                {/*<Link to="https://blockchain.intellectsoft.net/" className="offer-content-link-more"*/}
                                                {/*   onClick="ga('send', 'event', 'blockchain_lab', 'click', 'block_solution')">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/fin-tech.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    FinTech </h3>
                                                <p className="offer-content-description">
                                                    Meet the demands of modern customers in speed and security with scalable
                                                    financial technology. </p>
                                                {/*<Link to="solutions/fintech"*/}
                                                {/*   className="offer-content-link-more">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Augmented Reality Solutions </h3>
                                                <p className="offer-content-description">
                                                    Build better prototypes, improve the user experience, and lower costs with AR
                                                    solutions. </p>
                                                {/*<Link to="https://ar.intellectsoft.net/"*/}
                                                {/*   className="offer-content-link-more"*/}
                                                {/*   onClick="ga('send', 'event', 'ar_lab', 'click', 'block_solution')">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/cloud-computing.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Cloud Computing </h3>
                                                <p className="offer-content-description">
                                                    Use cloud computing solutions to create a scalable, flexible, and connected
                                                    enterprise environment. </p>
                                                {/*<Link to="solutions/azure" className="offer-content-link-more">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/artificial-intelligence.png').default}/>

                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Artificial Intelligence </h3>
                                                <p className="offer-content-description">
                                                    Custom AI-based solutions like machine and deep learning will help you automate
                                                    any operation. </p>
                                                {/*<Link to="solutions/ai"*/}
                                                {/*   className="offer-content-link-more">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/internet-of-things.png').default}/>

                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Internet of Things </h3>
                                                <p className="offer-content-description">
                                                    Secure IoT solutions will let you gather Big Data, optimise key processes, and
                                                    improve decision-making. </p>
                                                {/*<Link to="https://iot.intellectsoft.net/" */}
                                                {/*   className="offer-content-link-more"*/}
                                                {/*   onClick="ga('send', 'event', 'iot_lab', 'click', 'block_solution')">*/}
                                                {/*    view more*/}
                                                {/*    <i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                 </TabPanel>
                                <TabPanel header="Services" headerClassName="offers-tablinks" rightIcon="pi pi-user">
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Enterprise Software Development </h3>
                                                <p className="offer-content-description">
                                                    Create complex enterprise software, ensure reliable software integration,
                                                    modernise your legacy system. </p>
                                                {/*<Link to="services/enterprise-development" className="offer-content-link-more">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Mobile App Development </h3>
                                                <p className="offer-content-description">
                                                    Create an impactful mobile app that fits your brand and industry within a
                                                    shorter time frame. </p>
                                                {/*<Link to="services/mobile-development" className="offer-content-link-more">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    IT Consulting </h3>
                                                <p className="offer-content-description">
                                                    Trust our top minds to eliminate workflow pain points, implement new tech, and
                                                    consolidate app portfolios. </p>
                                                {/*<Link to="services/it-consulting-services" className="offer-content-link-more">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Dedicated Development Team </h3>
                                                <p className="offer-content-description">
                                                    Hire a loyal software development team with niche skills and deep
                                                    expertise. </p>
                                                {/*<Link to="services/team-extension"*/}
                                                {/*   className="offer-content-link-more">*/}
                                                {/*    view more<i className="isoi-angle-right"/>*/}
                                                {/*</Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    UI and UX Design </h3>
                                                <p className="offer-content-description">
                                                    Build the product you need on time with an experienced team that uses a clear
                                                    and effective design process. </p>
                                                {/*<Link to="services/ui-ux-design-services" className="offer-content-link-more">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    QA & Testing </h3>
                                                <p className="offer-content-description">
                                                    Turn to our experts to perform comprehensive, multi-stage testing and auditing
                                                    of your software. </p>
                                                {/*<Link to="services/qa-and-software-testing-services"*/}
                                                {/*   className="offer-content-link-more">view more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                </TabPanel>
                                <TabPanel header="Industries" headerClassName="offers-tablinks" leftIcon="pi pi-search" rightIcon="pi pi-check">
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Construction </h3>
                                                <p className="offer-content-description">
                                                    Advanced software solutions that take Construction into the Digital Era. </p>
                                                {/*<Link to="platforms/ar-construction" className="offer-content-link-more"*/}
                                                {/*   rel="nofollow">view more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Healthcare </h3>
                                                <p className="offer-content-description">
                                                    Patient-friendly software that empowers healthcare industry workers. </p>
                                                {/*<Link to="platforms/healthcare" className="offer-content-link-more" rel="nofollow">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Retail & eCommerce </h3>
                                                <p className="offer-content-description">
                                                    Engaging and exciting software solutions for modern retail. </p>
                                                {/*<Link to="platforms/ecommerce-retail" className="offer-content-link-more"*/}
                                                {/*   rel="nofollow">view more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Finance & Insurance </h3>
                                                <p className="offer-content-description">
                                                    Tackle Big Data and improve your operations with FinTech solutions. </p>
                                                {/*<Link to="platforms/insurance" className="offer-content-link-more" rel="nofollow">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Travel & Hospitality </h3>
                                                <p className="offer-content-description">
                                                    Extend the comfort of your resort with practical software solutions. </p>
                                                {/*<Link to="platforms/hospitality" className="offer-content-link-more" rel="nofollow">view*/}
                                                {/*    more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                                        <div className="tabcontent-item">
                                            <img className="offer-image" src={require('../img/home-offers/augmented-reality.png').default}/>
                                            <div className="offer-content">
                                                <h3 className="offer-content-title">
                                                    Logistics </h3>
                                                <p className="offer-content-description">
                                                    Use all the latest tech available to make your logistics run like
                                                    clockwork. </p>
                                                {/*<Link to="platforms/geolocation-navigation" className="offer-content-link-more"*/}
                                                {/*   rel="nofollow">view more<i className="isoi-angle-right"/></Link>*/}
                                            </div>
                                        </div>
                               </TabPanel>
                            </TabView>
                        </div>
                    </div>
                </section>
            </div>
        );
    }}

const Offers1 = () => {
    return (
        <div id="o-wrapper" className="wrapper overflow-hidden">
            <section className="section-offers">
                <div id="particles"
                     data-1500="background-position:0px 0px;"
                     data-2300="background-position:0px 360px;">
                </div>
                <div className="container">
                    <div className="offers">
                        {/*// <!--  Section title  -->*/}
                        <div className="offers-title">
                            <h2 className="title title--black">
                                What We Offer </h2>
                        </div>
                        {/*// <!--  Tab links  -->*/}
                        <div className="offers-tab">
                            <h4 id="defaultActiveTab" className="offers-tablinks" data-name="solutions">Solutions</h4>
                            <h4 className="offers-tablinks" data-name="services">Services</h4>
                            <h4 className="offers-tablinks" data-name="industries">Industries</h4>
                        </div>
                        {/*// <!--  Tab content  -->*/}
                        <div id="solutions" className="offers-tabcontent">
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require('../img/home-offers/blockchain-technology.png').default} alt=""/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Blockchain Development </h3>
                                    <p className="offer-content-description">
                                        Introduce the highest level of security and automate your operations with our
                                        blockchain solutions. </p>
                                    <Link to=""
                                       onClick="ga('send', 'event', 'blockchain_lab', 'click', 'block_solution')">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/fin-tech.png").default} alt=""/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        FinTech </h3>
                                    <p className="offer-content-description">
                                        Meet the demands of modern customers in speed and security with scalable
                                        financial technology. </p>
                                    <Link to="solutions/fintech" className="offer-content-link-more">view more<i
    className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default} alt=""/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Augmented Reality Solutions </h3>
                                    <p className="offer-content-description">
                                        Build better prototypes, improve the user experience, and lower costs with AR
                                        solutions. </p>
                                    <Link to=""
                                       onClick="ga('send', 'event', 'ar_lab', 'click', 'block_solution')">
                                        view more<i className="isoi-angle-right"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/cloud-computing.png").default} alt=""/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Cloud Computing </h3>
                                    <p className="offer-content-description">
                                        Use cloud computing solutions to create a scalable, flexible, and connected
                                        enterprise environment. </p>
                                    <Link to="solutions/azure" className="offer-content-link-more">
                                        view more<i className="isoi-angle-right"/>
                                    </Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/artificial-intelligence.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Artificial Intelligence </h3>
                                    <p className="offer-content-description">
                                        Custom AI-based solutions like machine and deep learning will help you automate
                                        any operation. </p>
                                    <Link to="solutions/ai" className="offer-content-link-more">view more<i
    className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/internet-of-things.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Internet of Things </h3>
                                    <p className="offer-content-description">
                                        Secure IoT solutions will let you gather Big Data, optimise key processes, and
                                        improve decision-making. </p>
                                    <Link to=""
                                       onClick="ga('send', 'event', 'iot_lab', 'click', 'block_solution')">
                                        view more<i className="isoi-angle-right"/>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <div id="services" className="offers-tabcontent">
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Enterprise Software Development </h3>
                                    <p className="offer-content-description">
                                        Create complex enterprise software, ensure reliable software integration,
                                        modernise your legacy system. </p>
                                    <Link to="services/enterprise-development" className="offer-content-link-more">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Mobile App Development </h3>
                                    <p className="offer-content-description">
                                        Create an impactful mobile app that fits your brand and industry within a
                                        shorter time frame. </p>
                                    <Link to="services/mobile-development" className="offer-content-link-more">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        IT Consulting </h3>
                                    <p className="offer-content-description">
                                        Trust our top minds to eliminate workflow pain points, implement new tech, and
                                        consolidate app portfolios. </p>
                                    <Link to="services/it-consulting-services" className="offer-content-link-more">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Dedicated Development Team </h3>
                                    <p className="offer-content-description">
                                        Hire a loyal software development team with niche skills and deep
                                        expertise. </p>
                                    <Link to="services/team-extension" className="offer-content-link-more">view more<i
    className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        UI and UX Design </h3>
                                    <p className="offer-content-description">
                                        Build the product you need on time with an experienced team that uses a clear
                                        and effective design process. </p>
                                    <Link to="services/ui-ux-design-services" className="offer-content-link-more">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        QA & Testing </h3>
                                    <p className="offer-content-description">
                                        Turn to our experts to perform comprehensive, multi-stage testing and auditing
                                        of your software. </p>
                                    <Link to=""
                                       className="offer-content-link-more">view more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                        </div>

                        <div id="industries" className="offers-tabcontent">
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Construction </h3>
                                    <p className="offer-content-description">
                                        Advanced software solutions that take Construction into the Digital Era. </p>
                                    <Link to=""
                                       rel="nofollow">view more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Healthcare </h3>
                                    <p className="offer-content-description">
                                        Patient-friendly software that empowers healthcare industry workers. </p>
                                    <Link to="platforms/healthcare" className="offer-content-link-more" rel="nofollow">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Retail & eCommerce </h3>
                                    <p className="offer-content-description">
                                        Engaging and exciting software solutions for modern retail. </p>
                                    <Link to=""
                                       rel="nofollow">view more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Finance & Insurance </h3>
                                    <p className="offer-content-description">
                                        Tackle Big Data and improve your operations with FinTech solutions. </p>
                                    <Link to="platforms/insurance" className="offer-content-link-more" rel="nofollow">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png").default}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Travel & Hospitality </h3>
                                    <p className="offer-content-description">
                                        Extend the comfort of your resort with practical software solutions. </p>
                                    <Link to="platforms/hospitality" className="offer-content-link-more" rel="nofollow">view
                                        more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                            <div className="tabcontent-item">
                                <img className="offer-image" src={require("../img/home-offers/augmented-reality.png")}/>
                                <div className="offer-content">
                                    <h3 className="offer-content-title">
                                        Logistics </h3>
                                    <p className="offer-content-description">
                                        Use all the latest tech available to make your logistics run like
                                        clockwork. </p>
                                    <Link to=""
                                       rel="nofollow">view more<i className="isoi-angle-right"/></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}


