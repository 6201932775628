import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__flexbox">
          <ul className="footer__menu">
            <li className="has-dropdown">
              <Link to="/services">Services</Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/services" hash="DevelopmentTeam">Dedicated Team</Link>
                </li>
                <li>
                  <Link to="/services" hash="MobileAppDevelopment">Mobile App Development</Link>
                </li>
                <li>
                  <Link to="/services#EnterpriseSoftwareDevelopment">Enterprise Software Development</Link>
                </li>
                <li>
                  <Link to="/services#SoftwareTestingandQA">Software Testing and QA</Link>
                </li>
                <li>
                  <Link to="/services#ITConsulting">IT Consulting</Link>
                </li>
                <li>
                  <Link to="/services#UIandUXServices">UI and UX Services</Link>
                </li>
                <li>
                  <Link to="/services#DevOps">Dev Ops</Link>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <Link to="/technologies">Technologies</Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/technologies" hash="EnterpriseSoftwareDevelopment">Enterprise Software Development</Link>
                </li>
                <li>
                  <Link to="/technologies" hash="WebDevelopment">Web Development </Link>
                </li>
                <li>
                  <Link to="/technologies" hash="BlockchainDevelopment">Blockchain Development</Link>
                </li>
                <li>
                  <Link to="/technologies" hash="IoTDevelopment">IoT Development</Link>
                </li>
                <li>
                  <Link to="/technologies" hash="MobileAppDevelopment">Mobile App Development</Link>
                </li>
                <li>
                  <Link to="/technologies#AIDevelopment">AI Development</Link>
                </li>
                <li>
                  <Link to="/technologies#ARandVRDevelopment">AR and VR Development</Link>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <Link to="/industries">Industries</Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/industries#Construction">Construction</Link>
                </li>
                <li>
                  <Link to="/industries#Healthcare">Healthcare</Link>
                </li>
                <li>
                  <Link to="/industries#Insurance">Insurance</Link>
                </li>
                <li>
                  <Link to="/industries#Retail&eCommerce">Retail & eCommerce</Link>
                </li>
                <li>
                  <Link to="/industries#Travel&Hospitality">Travel & Hospitality</Link>
                </li>
                <li>
                  <Link to="/industries#Events&Venues">Events & Venues</Link>
                </li>
                <li>
                  <Link to="/industries#Sports&Teams">Sports & Teams</Link>
                </li>
                <li>
                  <Link to="/industries#Musicians&Bands">Musicians & Bands</Link>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <Link to="/about">Company</Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/company">About</Link>
                </li>
                <li>
                  <Link to="/contact">Contacts</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to="/team">Team</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/partners">Partners</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/cases">Case Studies</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/blog">Blog</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to="/sitemap">Sitemap</Link>*/}
                {/*</li>*/}
              </ul>
            </li>
          </ul>
          <div className="footer__about">
            <div className="footer__address">
              <h4 className="footer__heading">Mauritius Island</h4>
              <div className="footer__office">
                <span className="footer__city">Cyber city</span>
                <span className="footer__phone">
                  +23059794040
                </span>
                <span className="footer__street">
                  {" "}
                  Tecsla Inc. Pty Ltd 1st Cyber city Tower, Ebene,
                  Cyber city Mauritius Island www.tecsla.com or
                  www.tecslahomes.com
                </span>
              </div>
            </div>
            <div className="footer__address">
              <div className="footer__office">
                <span className="footer__street" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer__copyright">
          <div className="footer__copy-container">
            <span className="footer__year">
              &copy; {(new Date()).getFullYear()} Tecsla Inc. Pty Ltd{" "}
            </span>
            {/*<Link*/}
            {/*  className="footer__privacy"*/}
            {/*  to="/privacy-policy"*/}
            {/*  rel="nofollow"*/}
            {/*>*/}
            {/*  Privacy Policy*/}
            {/*</Link>*/}
          </div>
          <div className="footer__social social-box">
            <div className="social-box__list">
              <a
                className="linkedin"
                href="https://www.linkedin.com/showcase/13355849"
                rel="nofollow"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                >
                  <path d="M2 0.3C3.1 0.3 4 1.2 4 2.3 4 3.4 3.1 4.3 2 4.3 0.9 4.3 0 3.4 0 2.3 0 1.2 0.9 0.3 2 0.3L2 0.3ZM0.3 16.9L0.3 5.8 3.7 5.8 3.7 16.9 0.3 16.9ZM5.9 5.8L9.2 5.8 9.2 7.3 9.2 7.3C9.7 6.5 10.8 5.5 12.5 5.5 16 5.5 16.6 7.8 16.6 10.8L16.6 16.9 13.2 16.9 13.2 11.5C13.2 10.2 13.1 8.6 11.4 8.6 9.6 8.6 9.3 10 9.3 11.4L9.3 16.9 5.9 16.9 5.9 5.8Z" />
                </svg>
              </a>
              <a
                className="facebook"
                href="https://www.facebook.com/tecslainc/"
                rel="nofollow"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="18"
                  viewBox="0 0 10 18"
                >
                  <path d="M8 2.9C8.5 2.9 9 2.9 9.5 2.9L9.8 2.9 9.8 0.1C9.5 0.1 9.2 0.1 8.9 0 8.3 0 7.8 0 7.3 0 6.4 0 5.6 0.2 4.9 0.7 4.2 1.3 3.7 2.1 3.6 3 3.5 3.4 3.5 3.8 3.5 4.2 3.5 4.8 3.5 5.4 3.5 6.1L3.5 6.3 0.8 6.3 0.8 9.4 3.4 9.4 3.4 17.1 6.7 17.1 6.7 9.4 9.3 9.4C9.4 8.4 9.5 7.4 9.7 6.3 9.5 6.3 9.3 6.3 9.1 6.3 8.3 6.3 6.6 6.3 6.6 6.3 6.6 6.3 6.6 4.8 6.7 4.1 6.7 3.2 7.2 2.9 8 2.9" />
                </svg>
              </a>
              <a
                className="twitter"
                href="https://twitter.com/Tecslainc"
                rel="nofollow"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="15"
                  viewBox="0 0 19 15"
                >
                  <path d="M5.5 11.6C3.6 11.5 2.4 10.1 2 9 2.6 9.1 3.1 9.1 3.6 8.9 3.7 8.9 3.7 8.9 3.7 8.9 2.6 8.7 1.8 8.1 1.3 7.2 0.9 6.6 0.7 5.9 0.7 5.2 1.2 5.5 1.8 5.6 2.4 5.7 1.6 5.1 1.1 4.3 0.8 3.4 0.6 2.4 0.8 1.5 1.2 0.7 3.3 3.1 5.8 4.4 8.9 4.6 8.9 4.4 8.9 4.3 8.9 4.2 8.8 3.3 9 2.5 9.4 1.7 10 0.8 10.9 0.2 12 0.1 13.2-0.1 14.3 0.2 15.2 1.1 15.3 1.2 15.3 1.2 15.4 1.2 16.2 1 16.9 0.7 17.6 0.3 17.6 0.3 17.6 0.3 17.7 0.3 17.7 0.3 17.7 0.3 17.7 0.3 17.4 1.2 16.8 1.8 16.1 2.3 16.8 2.2 17.5 2.1 18.2 1.8 18.2 1.8 18.2 1.8 18.2 1.8 18 2 17.9 2.2 17.7 2.3 17.3 2.8 16.9 3.3 16.4 3.6 16.3 3.7 16.3 3.7 16.3 3.8 16.3 4.3 16.3 4.9 16.3 5.4 16.1 6.6 15.8 7.7 15.3 8.7 14.8 9.8 14.1 10.8 13.3 11.7 11.9 13.1 10.1 14.1 8.1 14.5 7.5 14.7 6.8 14.8 6.1 14.8 3.9 14.8 1.9 14.3 0.1 13.2 0.1 13.2 0 13.2 0 13.1 1.3 13.3 2.5 13.1 3.7 12.6 4.4 12.4 5 12 5.5 11.6" />
                </svg>
              </a>
              {/*  <Link className="flickr" to="http://flickr.com/photos/intellectsoft/" rel="nofollow"
                               target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
                                    <path className="dribble-red"
                                          d="M19.3 4.9C19.3 7.2 17.4 9.1 15.1 9.1 12.7 9.1 10.9 7.2 10.9 4.9 10.9 2.6 12.7 0.7 15.1 0.7 17.4 0.7 19.3 2.6 19.3 4.9"/>
                                    <path className="dribble-blue"
                                          d="M4.2 9.1C1.9 9.1 0 7.2 0 4.9 0 2.6 1.9 0.7 4.2 0.7 6.5 0.7 8.4 2.6 8.4 4.9 8.4 7.2 6.5 9.1 4.2 9.1Z"/>
                                </svg>
                            </Link>*/}
              {/*<Link className="youtube"
                                to="https://www.youtube.com/channel/UCAXWcrzB27wtArbUJGfy8oA/featured"
                                rel="nofollow" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 20" height="20">
                                    <g transform="scale(0.04)">
                                        <path
                                            d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                                    </g>
                                </svg>
                            </Link>*/}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
