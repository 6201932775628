import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Offices = () => {
    return (
        <div id="o-wrapper" className="wrapper overflow-hidden">

            <section className="offices">
                <div className="container">
                    <div className="offices__title">
                        <h2>Our Offices</h2>
                        <p>Tecsla operates in multiple offices and development centers across the continent of Africa. Reach out to us for software development services in your region.</p>
                    </div>

                    <div id="officesSlider" className="offices-slider owl-carousel">
                    <OwlCarousel
                        className="owl-theme"
                        margin={1}
                        items={3}
                        lazyLoad
                        nav
                        dots={true}
                        smartSpeed={600}
                        loop
                        responsive={{
                            0: {
                                items: 1,
                                nav: true
                            },
                            600: {
                                items: 3,
                                nav: false
                            }
                        }}
                        // autoplayTimeout={3000}
                        // responsiveClass={true}
                    >
                        <div className="offices-slider__item item1" >

                            <div className="offices-slider__content">
                                <div className="offices-slider__overlay"/>

                                <div className="offices-slider__text">
                                    <div className="offices-slider__city">MAURITIUS OFFICE<br />CYBER CITY</div>
                                    <div className="offices-slider__address">
                                        <p>Tecsla Inc. Pty Ltd 1st Cyber city Tower, Ebene, Mauritius Island Cyber city Plaine Wilhems 72201</p>
                                        <p>+230 59794040</p>
                                        <p><a href="mailto:itservices@tecsla.com ">itservices@tecsla.com </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="offices-slider__item item2" >
                            <div className="offices-slider__content">
                                <div className="offices-slider__overlay"/>

                                <div className="offices-slider__text">
                                    <div className="offices-slider__city">KENYA OFFICE<br />NAIROBI</div>
                                    <div className="offices-slider__address">
                                        <p>Pioneer International Uni. road. P. O Box 33421-00600, Ngara</p>
                                        <p>+230 59794040</p>
                                        <p><a href="mailto:itservices@tecsla.com ">itservices@tecsla.com </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="offices-slider__item item3" >
                            <div className="offices-slider__content">
                                <div className="offices-slider__overlay"/>

                                <div className="offices-slider__text">
                                    <div className="offices-slider__city">NIGERIA OFFICE<br />LAGOS</div>
                                    <div className="offices-slider__address">
                                        <p>Suite 37, Dutch Plaza Ikeja Lagos Nigeria.</p>
                                        <p>+234 8061505268</p>
                                        <p><a href="mailto:admin@tecsla.com">admin@tecsla.com </a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="offices-slider__item" data-src="img/home-offices/oslo-norway.jpg">*/}
                            {/*<div className="offices-slider__content">*/}
                                {/*<div className="offices-slider__overlay"></div>*/}

                                {/*<div className="offices-slider__text">*/}
                                    {/*<div className="offices-slider__city">NORWAY<br />OSLO</div>*/}
                                    {/*<div className="offices-slider__address">*/}
                                        {/*<p>Bogstadveien 27B</p>*/}
                                        {/*<p>+47 21 08 18 50</p>*/}
                                        {/*<p><a href="mailto:info@intellectsoft.no">info@intellectsoft.no</a></p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="offices-slider__item "*/}
                             {/*data-src="/assets/5d4a9b59/img/home-offices/ukraine-kyiv.jpg">*/}
                            {/*<div className="offices-slider__content">*/}
                                {/*<div className="offices-slider__overlay"></div>*/}

                                {/*<div className="offices-slider__text">*/}
                                    {/*<div className="offices-slider__city">UKRAINE<br />KYIV</div>*/}
                                    {/*<div className="offices-slider__address">*/}
                                        {/*<p>15, Leiptsyzka St.</p>*/}
                                        {/*<p>+380 (44) 332-24-36</p>*/}
                                        {/*<p><a href="mailto:hr@intellectsoft.net">hr@intellectsoft.net</a></p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="offices-slider__item "*/}
                             {/*data-src="/assets/5d4a9b59/img/home-offices/minsk-belarus.jpg">*/}
                            {/*<div className="offices-slider__content">*/}
                                {/*<div className="offices-slider__overlay"></div>*/}

                                {/*<div className="offices-slider__text">*/}
                                    {/*<div className="offices-slider__city">BELARUS<br />MINSK</div>*/}
                                    {/*<div className="offices-slider__address">*/}
                                        {/*<p>57, Dzerzhinskogo Ave, office 14-1</p>*/}
                                        {/*<p>+375 (17) 397-89-72</p>*/}
                                        {/*<p><a href="mailto:hr@intellectsoft.net">hr@intellectsoft.net</a></p>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}


            </OwlCarousel>
                </div>
                </div>
            </section>


        </div>
    )
}

export default Offices