import React from "react";
//import Mailchimp from 'react-mailchimp-form'

//import Slider from "../components/Slider";
//import Who from "../components/Who";

const Services = () => {
  return (
    // <div id="o-wrapper" className="wrapper overflow-hidden">
    //   <section className="hero hero--600">
    //     <div className="container">
    //       <div className="hero__content">
    //         <h1 className="title title--uppercase">
    //           <span className="title__preheader title__preheader--tag">
    //             INDUSTRIES
    //           </span>
    //           Software Solutions for Big Industries{" "}
    //         </h1>
    //         <p className="hero__lead">
    //           Our goal is to help organizations in different industries to
    //           embrace our disruptive ideas that is backed by digitally oriented
    //           expertise to provide impactful software technology that are
    //           trending in today’s business environment.
    //         </p>
    //         <p className="hero__lead">
    //           Since 2016, Tescla has constantly provides and collaborating with
    //           established and fortune 500 brands to drive corporate vision and
    //           goals through software and application development.
    //         </p>
    //         <p className="hero__lead">
    //           We will be delighted to help you build a first-hand technology
    //           solution that will respond rapidly to your technological needs
    //           efficiently.
    //         </p>
    //       </div>
    //     </div>
    //   </section>
      <div>
        <section className="header header--industries">
          <div className="container">
            <h1 className="title title--uppercase">
              <span className="title__preheader title__preheader--tag">
                INDUSTRIES
              </span>
              Software Solutions for Big Industries{" "}
            </h1>
            <p className="hero__lead">
              Our goal is to help organizations in different industries to
              embrace our disruptive ideas that is backed by digitally oriented
              expertise to provide impactful software technology that are
              trending in today’s business environment.
            </p>
            <p className="hero__lead">
              Since 2016, Tescla has constantly provides and collaborating with
              established and fortune 500 brands to drive corporate vision and
              goals through software and application development.
            </p>
            <p className="hero__lead">
              We will be delighted to help you build a first-hand technology
              solution that will respond rapidly to your technological needs
              efficiently.
            </p>
          </div>
        </section>
        <div className="bullseye">
          <div className="bullseye__circle" />
          <div className="bullseye__item bullseye__item--25">
            <div
              className="bullseye__ipad"
              data-bg="../img/bullseye-ipad.png"
              // style = 'background-image: url("/img/bullseye-ipad.png"); opacity: 1;'
            />
          </div>

          <div className="bullseye__item bullseye__item--50">
            <div className="bullseye__content">
              <h3>COMPLEX PLATFORM &amp;&nbsp; PARSONALIZED DEVELOPMENT </h3>
              <h3>CONVERGED IN ONE PLACE WITH A FIRST HAND SOLUTION</h3>
              <p>
                Embrace our disruptive ideas that is backed by digitally oriented
                expertise and lets help to build a definitive technology solution
                for your business.
              </p>

              <div className="bullseye__arrow">
                <span>Tecsla, Inc... Consider it done</span>
              </div>
            </div>
          </div>

          <div className="bullseye__item bullseye__item--25">
            <div
              className="bullseye__macbook"
              data-bg="../img/bullseye-macbook.png"
              //  style='background-image: url("https://www.intellectsoft.net/assets/f81ff1b9/img/bullseye-macbook.png"); opacity: 1;'
            />
          </div>
        </div>
        <div className="industries-section">
          <div className="divider-b-75 divider-t-100">
            <div className="container">
              <div className="grid grid--column grid--center">
                <h2 className="title title--black title--medium title--uppercase">
                  Software Solutions for Big Industries
                </h2>
              </div>
            </div>
          </div>
          <div className="snakepit">
            <div className="container">
              <div
                className="snakepit__phone"
                data-bg="../img/construction-m.png"
                //  style='background-image: url("https://www.intellectsoft.net/assets/f81ff1b9/img/construction-m.png"); opacity: 1;'
              />
              <div className="snakepit__list owl-carousel">
                <div className="snakepit__item" id={"Construction"}>
                  <div className="snakepit__content snakepit__rectangle--right top-border">
                    <h3 className="snakepit__heading">Construction</h3>
                    <p className="snakepit__description">
                      Work in a flexible condition with remote cooperation,
                      automated detectors for hazard and lickage. Get more with
                      our AR solutions.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/construction.jpg"
                      alt="Construction"
                    />{" "}
                    {/*<a*/}
                    {/*  href="/platforms/ar-construction"*/}
                    {/*  className="snakepit__link--more"*/}
                    {/*>*/}
                    {/*  view more*/}
                    {/*  <i className="isoi-angle-right" />*/}
                    {/*</a>*/}
                  </div>
                </div>

                <div className="snakepit__item" id={"Healthcare"}>
                  <div className="snakepit__content snakepit__rectangle--left top-border">
                    <h3 className="snakepit__heading">Healthcare</h3>
                    <p className="snakepit__description">
                      Ehealth solutions: Appointment scheduling notification,
                      patient tracking, data management and analysis.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/healthcare.jpg"
                      alt="Healthcare"
                    />{" "}
                    <a
                      href="/platforms/healthcare"
                      className="snakepit__link--more"
                    >
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Insurance"}>
                  <div className="snakepit__content snakepit__rectangle--right">
                    <h3 className="snakepit__heading">Insurance</h3>
                    <p className="snakepit__description">
                      Portfolio and Claim management, Notifications, AI,
                      cloud-base-system and data solutions, SOS live streaming,
                      and more.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/insurance.jpg"
                      alt="Insurance"
                    />{" "}
                    <a
                      href="/platforms/insurance"
                      className="snakepit__link--more"
                    >
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Retail&eCommerce"}>
                  <div className="snakepit__content snakepit__rectangle--left">
                    <h3 className="snakepit__heading">Retail &amp; eCommerce</h3>
                    <p className="snakepit__description">
                      Big Data, ecommerce software development, Navigation system
                      and more from our AR solutions.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/retail.jpg"
                      alt="Retail &amp; eCommerce"
                    />{" "}
                    <a
                      href="/platforms/ecommerce-retail"
                      className="snakepit__link--more"
                    >
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Travel&Hospitality"}>
                  <div className="snakepit__content snakepit__rectangle--right">
                    <h3 className="snakepit__heading">
                      Travel &amp; Hospitality
                    </h3>
                    <p className="snakepit__description">
                      IoT solutions with the latest technologies, and connected to
                      all the trending hotel applications and features.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/travel.jpg"
                      alt="Travel &amp; Hospitality"
                    />{" "}
                    <a
                      href="/platforms/hospitality"
                      className="snakepit__link--more"
                    >
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Events&Venues"}>
                  <div className="snakepit__content snakepit__rectangle--left">
                    <h3 className="snakepit__heading">Events &amp; Venues</h3>
                    <p className="snakepit__description">
                      AR and Beacons, Live-streaming, and event pass and venue
                      management software, data solutions.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/events.jpg"
                      alt="Events &amp; Venues"
                    />{" "}
                    <a href="/platforms/events" className="snakepit__link--more">
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Sports&Teams"}>
                  <div className="snakepit__content snakepit__rectangle--right bottom-border">
                    <h3 className="snakepit__heading">Sports &amp; Teams</h3>
                    <p className="snakepit__description">
                      IoT solutions, sports apps, chatbots, beacons, geofencing,
                      and many more.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/sports.jpg"
                      alt="Sports &amp; Teams"
                    />{" "}
                    <a
                      href="/platforms/sports-apps"
                      className="snakepit__link--more"
                    >
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>

                <div className="snakepit__item" id={"Musicians&Bands"}>
                  <div className="snakepit__content snakepit__rectangle--left bottom-border">
                    <h3 className="snakepit__heading">Musicians &amp; Bands</h3>
                    <p className="snakepit__description">
                      AR solution: Personalized in-app chat, studio and shop,
                      streaming, subscription, Map and tour dates, and more.
                    </p>
                    <img
                      className="snakepit__screen"
                      src="../img/musicians.jpg"
                      alt="Musicians &amp; Bands"
                    />{" "}
                    <a href="/platforms/music" className="snakepit__link--more">
                      view more
                      <i className="isoi-angle-right" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="snakepit__preload">
                <img src="../img/construction-m.png" alt="Construction" />
                <img src="../img/healthcare-m.png" alt="Healthcare" />
                <img src="../img/insurance-m.png" alt="Insurance" />
                <img src="../img/retail-m.png" alt="Retail &amp; eCommerce" />
                <img src="../img/travel-m.png" alt="Travel &amp; Hospitality" />
                <img src="../img/events-m.png" alt="Events &amp; Venues" />
                <img src="../img/sports-m.png" alt="Sports &amp; Teams" />
                <img src="../img/musicians-m.png" alt="Musicians &amp; Bands" />
              </div>
            </div>
          </div>
        </div>
        <div className="apocalypse apocalypse--platforms">
          <div className="container">
            <h2 className="apocalypse__title">The Latest Tech</h2>
            <div className="apocalypse__list">
              <div className="apocalypse__item">
                <i className="isoi-virtual-reality apocalypse__icon" />
                <div className="apocalypse__content ">
                  <h3 className="apocalypse__heading">VIRTUAL REALITY</h3>
                  <p className="apocalypse__description">
                    Add a disruptive idea to your business by developing a
                    first-hand VR experience that will cut the eyers of your
                    clients and lingers for quite a while..
                  </p>
                </div>
              </div>
              <div className="apocalypse__item">
                <i className="isoi-augmented-reality apocalypse__icon" />
                <div className="apocalypse__content ">
                  <h3 className="apocalypse__heading">AUGMENTED REALITY</h3>
                  <p className="apocalypse__description">
                    Embrace a new idea by adding extra layer to your business with
                    AR, bringing a new value and feelings to your clients directly
                    on the screens to their mobile devices.
                  </p>
                </div>
              </div>
              <div className="apocalypse__item">
                <i className="isoi-ai-assistant apocalypse__icon" />
                <div className="apocalypse__content ">
                  <h3 className="apocalypse__heading">ARTIFICIAL INTELLIGENCE</h3>
                  <p className="apocalypse__description">
                    Embrace deep learning algorithms and machine to streamline
                    operations, lessen expenses, and smart improvement of your
                    business.
                  </p>
                </div>
              </div>
              <div className="apocalypse__item">
                <i className="isoi-beacons apocalypse__icon" />
                <div className="apocalypse__content ">
                  <h3 className="apocalypse__heading">INTERNET OF THINGS</h3>
                  <p className="apocalypse__description">
                    Connect your devices in a secure ecosystem that gathers Big
                    Data, helping you improve all key operations and unlock . Link
                    your devices in a secured-cloud-based environment that can
                    assemble Big Data, helping you improve every key operations
                    and open up a high level of production output.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="divider-b-75 divider-t-100">
          <div className="container">
            <div className="grid grid--column grid--center">
              <h2 className="title title--black title--medium title--uppercase">
                App Management and Analytics
              </h2>
            </div>
          </div>
        </div>
        <div className="planalitics">
        <div className="container">
          <div className="planalitics__list">
            <ul>
              <li>
                <i className="isoi isoi-content-management-2" />
                <h3>Content Management</h3>
                <p>
                  Rapidly load, oversee, and store every one of your data in one
                  clean, extensive, and intuitive CMS. Promptly preview your
                  information in the dashboard or in a demo.
                </p>
              </li>
              <li>
                <i className="isoi isoi-analytics" />
                <h3>All the Analytics</h3>
                <p>
                  Effectively access your business informations, Big Data, and
                  reports. preview all significant investigation in a clean,
                  exquisite charts that enable you to settle on reliable choice
                  of information.
                </p>
              </li>
              <li>
                <i className="isoi isoi-mobile-transfers" />
                <h3>Integrate Your Operations</h3>
                <p>
                  Store your information in the cloud, increase commitment with
                  your targeted and sectioned plans, coordinate web based
                  activities, use Amazon S3 credentials, and the sky is the
                  limit from there.
                </p>
              </li>
            </ul>
          </div>

          <div
            className="planalitics__image"
            data-bg="../img/image-planalitics.png"
            // style='background-image: url("https://www.intellectsoft.net/assets/f81ff1b9/img/image-planalitics.png"); opacity: 1;'
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
