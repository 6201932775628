import React, { useState } from "react";
import './navbar.css';
import {Link} from "react-router-dom";
import tecslaLogo from '../../img/logo.png';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    return (
        <nav>
            <div className="container">
                <div className="tecsla-navbar">
                    <div className="tecsla-navbar-brand-container">
                        {/*<Link className="tecsla-nav-logo logo-white" to="/" />*/}
                        <Link className="tecsla-navbar-brand" to="/">
                            <img className="tecsla-navbar-logo" src={ tecslaLogo } alt={"Tecsla Logo"} />
                        </Link>
                        <button className={"tecsla-menu-toggle-btn"} onClick={()=> setIsMobileMenuOpen(!isMobileMenuOpen)}>
                            <span className={"icon nav-icon-5 " + (isMobileMenuOpen ? 'open' : '')} >
                                <span/>
                                <span/>
                                <span/>
                            </span>
                        </button>
                    </div>

                    <div className="tecsla-nav__list-wrapper" style={{ display: isMobileMenuOpen ? 'flex' : ''}}>
                        <ul className="tecsla-nav__list">
                            <li className="tecsla-menu-item">
                                <Link to="/technologies">Technologies</Link>
                            </li>

                            <li className="tecsla-menu-item">
                                <Link to="/services">Services</Link>
                            </li>
                            <li className="tecsla-menu-item">
                                <Link to="/industries">Industries</Link>
                            </li>

                            <li className="tecsla-menu-item">
                                <Link to="/clients">Clients</Link>
                            </li>
                            <li className="tecsla-menu-item">
                                <Link to="/company">Company</Link>
                            </li>
                            <li className="tecsla-menu-item">
                                <Link to="/contact" className={"btn"}>Get in Touch</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;