import React from "react";
//import Mailchimp from 'react-mailchimp-form'

//import Slider from "../components/Slider";
//import Who from "../components/Who";

const Services = () => {
  return (
    <div>
      <section className="header header--services">
        <div className="container">
          <h1 className="title title--uppercase title--margin-top white-text">
            <span className="title__preheader title__preheader--tag">
              services
            </span>
            ENTERPRISE SOFTWARE DEVELOPMENT SERVICES{" "}
          </h1>
          <p className="header__lead">
          Our expertise have over ten years of experience in the ICT field thus: this means automated quality assurance with a wider array of enterprise software solution to help you build an impactful technology solutions that will work across all platforms.
          </p>
        </div>
      </section>

      <section className="services">
        <div className="container">
          <h2 className="services__title">
          PERSONALIZED SOFTWARE DEVELOPMENT SERVICES
          </h2>
          <p className="services__description">
          To solve your project problems; be it on a daily, and small, medium and large scale, we have put in place a team of full-stacks IT professionals to get the work done.

Tecsla’s software development services constantly adopts emerging technologies to meet the demands of your projects. 

Let us build a mobile app that ticks all boxes for you and finish your digital (process) transformation with our first-hand IT consulting.
          </p>
          <div className="services__items">
            <div className="items items--col-3">
              <div className="item" id={"DevelopmentTeam"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/dedicated-team.svg").default}
                    alt="Dedicated Development Teams"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">
                  Our Development Team
                  </h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">
                    Our Development Team
                    </h3>
                    <p className="item__link-text">
                    With our dedicated in-house full stacks Professionals in all our offices across Africa, your project is in safe hands.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
              <div className="item" id={"MobileAppDevelopment"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/mobile-app-dev.svg").default}
                    alt="Mobile App Developments"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">
                    Mobile App Development
                  </h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">Mobile App Development</h3>
                    <p className="item__link-text">
                    With our mobile app development services, we will ensure that your required deadlines are met with a taylor-made mobile platform that is powered by the latest tech.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
              <div className="item" id={"EnterpriseSoftwareDevelopment"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/enterprise.svg").default}
                    alt="Enterprise Software Development"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">
                    Enterprise Software Development
                  </h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">
                      Enterprise Software Development
                    </h3>
                    <p className="item__link-text">
                    support and reduce operations with adaptability and scalability solutions that are well-incorporated with programs that fuses the most recent tech and organized your other system software.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
            </div>

            <div className="items items--col-sm-2 items--col-4">
              <div className="item" id={"SoftwareTestingandQA"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/quality-assurance.svg").default}
                    alt="Software Testing and QA"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">
                    Software <span className="nowrap">QA</span>
                  </h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">
                      Software <span className="nowrap">QA</span>
                    </h3>
                    <p className="item__link-text">
                    To guarantee smooth development process of your product, we'll utilize our product testing administrations; a  built up model of an automated software testing and a streamlined QA life-cycle.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
              <div className="item" id={"ITConsulting"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/it-consulting.svg").default}
                    alt="IT Consulting"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">IT Consulting</h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">IT Consulting</h3>
                    <p className="item__link-text">
                    We'll deploy top personel in accordance with your business objectives to perform an effective process transformation of your enterprise.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
              <div className="item" id={"UIandUXServices"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/page.svg").default}
                    alt="UI and UX Services"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">
                    UI and UX Services
                  </h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">UI and UX Services</h3>
                    <p className="item__link-text">
                    We'll design the product as you imagined it, with a disruptive idea that you will appreciate. Our UI and UX configuration administrations depend on built up principles with ten-year-expertise.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
              <div className="item" id={"DevOps"}>
                <div className="item__description">
                  <img
                    src={require("../img/services/devops.svg").default}
                    alt="DevOps"
                    className="item__description-logo"
                  />
                  <h3 className="item__description-title">DevOps</h3>
                </div>
                <div className="item__link">
                  <a>
                    <h3 className="item__link-title">DevOps</h3>
                    <p className="item__link-text">
                    Guarantee your product improvement procedure delivers great-quality software persistently and rapidly with our DevOps administrations.
                    </p>
                    {/*<span className="item__more">*/}
                    {/*  view more<i className="isoi-angle-right" />*/}
                    {/*</span>*/}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
