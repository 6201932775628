import React from "react";
import {Link} from "react-router-dom";

const Clients = () => {
  return (
      <div>
        <section className="header header--clients">
          <div className="container">
            <h1 className="title title--uppercase title--margin-top white-text">
              <span className="title__preheader title__preheader--tag">
                Clients
              </span>
                Solid Code.
                <br />
                FULL STACKS DEVELOPERS
                <br />
                REAL-TIME-RESULTS.
            </h1>
            <p className="header__lead">
              We pride ourselves with the knowledge and experience we poses in the industry. This means real time result with the expertise of Our full-stack professionals to help you overcome the challenges of achieving a successful tech projects.
            </p>
          </div>
        </section>

      {/*<div className="ranking">*/}
      {/*  <div className="container">*/}
      {/*    <h2 className="ranking__title">Awards &amp; Recognition</h2>*/}
      {/*    <div className="owl-carousel owl-loaded owl-drag" id="ranking">*/}
      {/*      <div className="owl-stage-outer">*/}
      {/*        <div*/}
      {/*          className="owl-stage"*/}
      {/*          style={{*/}
      {/*            transform: "translate3d(-1205px, 0px, 0px)",*/}
      {/*            transition: "all 2.4s ease 0s",*/}
      {/*            width: "2411px"*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/clutch-global-2018.png").default}*/}
      {/*                alt="Clutch Global 2018"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/iaop.png").default}*/}
      {/*                alt="IAOP"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/inc-5000.png").default}*/}
      {/*                alt="Inc 5000"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/stevies.png").default}*/}
      {/*                alt="The Stevies"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/top-design.png").default}*/}
      {/*                alt="Top Design Firms"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/goodfirms-software.png").default}*/}
      {/*                alt="Goodfirms Software"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/clutch-blockchain.png").default}*/}
      {/*                alt="Clutch Blockchain"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/techies.png").default}*/}
      {/*                alt="Techies"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/itfirms.png").default}*/}
      {/*                alt="IT Firms"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/business-apps.png").default}*/}
      {/*                alt="Business of Apps"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/goodfirms.png").default}*/}
      {/*                alt="Goodfirms"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/clutch-2017.png").default}*/}
      {/*                alt="Clutch 2017"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/appindex.png").default}*/}
      {/*                alt="Appindex"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div*/}
      {/*            className="owl-item active"*/}
      {/*            style={{ width: "167.143px", margin_right: "5px" }}*/}
      {/*          >*/}
      {/*            <div className="ranking__item item">*/}
      {/*              <img*/}
      {/*                className="ranking__img"*/}
      {/*                src={require("../img/ratings-logos/ibm.png").default}*/}
      {/*                alt="IBM Essentials"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="owl-nav disabled">*/}
      {/*        <div className="owl-prev">prev</div>*/}
      {/*        <div className="owl-next">next</div>*/}
      {/*      </div>*/}
      {/*      <div className="owl-dots">*/}
      {/*        <div className="owl-dot">*/}
      {/*          <span*/}
      {/*            style={{ border_left: "23.4167px solid rgb(0, 110, 250)" }}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <div className="owl-dot active">*/}
      {/*          <span style={{ border_color: "rgba(0, 110, 250, 0.533)" }} />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="stats">
        <div className="bg-stats-clients">
          <img
            className="bg-img"
            src={require("../img/bg-stats-clients.jpg").default}
            alt="Clients"
          />
        </div>
        <div className="container">
          <h2 className="stats__title">
            We are what our clients need us to be.
          </h2>
          <p className="stats__lead">
            They trust us to help them create impactful software solutions.
          </p>
          <div className="stats__wrapper">
            <div className="stats__item">
              <div className="stats__content">
                <p className="stats__number stats__number--massive">20</p>
                <h3 className="stats__heading">Global Clients</h3>
                <p className="stats__text">
                  Across 40+ countries and 6 continents.
                </p>
              </div>
            </div>

            <div className="stats__item">
              <div className="stats__content">
                <p className="stats__number stats__number--massive">85%</p>
                <h3 className="stats__heading">Returning Clients</h3>
                <p className="stats__text">
                  Approach us to improve and expand their solutions.
                </p>
              </div>
            </div>

            <div className="stats__item">
              <div className="stats__content">
                <p className="stats__number stats__number--massive">60%</p>
                <h3 className="stats__heading">Loyal Clients</h3>
                <p className="stats__text">
                  Have continued to choose Tecsla for over 3 years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="icons">
        <div className="container">
          <h2>Explore our projects</h2>
          <ul>
            <li>
              <i className="isoi isoi-software-engineering" />
              <h3>Software Engineering</h3>
            </li>
            <li>
              <i className="isoi isoi-intelligent-consulting" />
              <h3>Intelligent Consulting</h3>
            </li>
            <li>
              <i className="isoi isoi-intelligent-solutions" />
              <h3>Hi-Tech Solutions</h3>
            </li>
            <li>
              <i className="isoi isoi-business-intelligence" />
              <h3>IoT and AI Development</h3>
            </li>
          </ul>
        </div>
      </div>
      <div className="clients">
        <div className="container">
          <div className="clients__grid">
            <div className="clients__item">
              <div className="clients__content">
                {/*EIF*/}
                <div className="clients__media">
                  <div
                      className="clients__media-inner"
                      style={{ background: "{require('../img/new/eif-logo.png').default}" }}
                  />
                  <a href="https://eif.app/">
                    <img
                        className="clients__logo"
                        src={require("../img/new/eif-logo.png").default}
                        alt="Cirruspath"
                    />
                  </a>
                </div>
                <div className="clients__text">
                  <span className="clients__tag">SaaS</span>
                  <a href="https://eif.app/">
                    <h3 className="clients__title">EIF</h3>
                  </a>
                  <p className="clients__description">
                    Every Innovative Foreman is a construction management application that helps with the tracking, monitoring; it also organise your files, and much more through out the entire project timeline.
                  </p>
                  <a href="https://eif.app/" className="clients__link--more">
                    view more
                    <i className="isoi-angle-right" />
                  </a>
                </div>
              </div>
            </div>

            {/*TecslaHomes*/}
            <div className="clients__item">
              <div className="clients__content">
                <div className="clients__media">
                  <div
                      className="clients__media-inner"
                      style={{
                        background: "{require('../img/new/tecslahomes-logo.png').default}"
                      }}
                  />
                  <a href="https://tecslahomes.com/">
                    <img
                        className="clients__logo"
                        src={require("../img/new/tecslahomes-logo.png").default}
                        alt="TecslaHomes"
                    />
                  </a>
                </div>

                <div className="clients__text">
                  <span className="clients__tag">Real Estate</span>
                  <a href="https://tecslahomes.com/">
                    <h3 className="clients__title">TecslaHomes</h3>
                  </a>
                  <p className="clients__description">
                    TecslaHomes, REAL ESTATE AND PROPERTY MANAGEMENT AGENCY...
                  </p>
                  <a href="https://tecslahomes.com/" className="clients__link--more">
                    view more
                    <i className="isoi-angle-right" />
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/*<div className="section-margin">*/}
      {/*  <div className="container text-center">*/}
      {/*    <Link to="/cases" className="button button--clear button--blue">*/}
      {/*      More success stories <i className="isoi-angle-right" />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <section
        className="bg-fluid--blured"
        style={{ background: "{require('../img/bg-home-company.jpg').default}" }}
      >
        <div className="grid">
          <div className="blue-box">
            <div
              className="blue-box__bg"
              style={{ background: "{require('../img/bg-home-company.jpg').default}" }}
            />
            <div className="blue-box__content">
              <h2 className="blue-box__title">ABOUT TECSLA</h2>
              <p className="blue-box__lead">
              Established in 2013, since then it has picked its growth and earn the trust of many businesses in terms of providing solutions for enterprise-grade / software architectural project.

Our goal is to help organizations in different industries to embrace our disruptive ideas that is backed by digitally oriented expertise to provide an impactful software technology that are trending in today’s business environment.
              </p>
              <Link className="button button--clear button--white" to="/contact">
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
