import React from 'react'
//import Mailchimp from 'react-mailchimp-form'


import Slider from '../components/Slider';
import Who from '../components/Who';
import Offers from '../components/Offers';
import Offices from '../components/Offices';
import Blogs from '../components/Blogs';
import Contacts from '../components/Contacts';
import ClientsSection from '../components/ClientsSection';

const Home = () => {
    return (
        <div>

           <Slider/>
            {/*<section id="" className="cemetery">*/}
           <Who/>
            {/*</section>*/}
            {/*/!*<section id="" className="cemetery">*!/*/}
            <Offers/>
            <ClientsSection/>
            {/*/!*</section>*!/*/}
            {/*/!*<section id="" className="cemetery">*!/*/}
            {/*<Contacts/>*/}
            {/*/!*</section>*!/*/}
            {/*/!*<section id="" className="cemetery">*!/*/}
            {/*<Blogs/>*/}
            {/*/!*</section>*!/*/}
            {/*/!*<section id="" className="cemetery">*!/*/}
            <Offices/>
            {/*/!*</section>*!/*/}

        </div>
    )
}

export default Home