import React from "react";
//import Mailchimp from 'react-mailchimp-form'

import Slider from "../components/Slider";
import Who from "../components/Who";

const Technologies = () => {
  return (
    <div id="o-wrapper" className="wrapper overflow-hidden">
      <section className="header header--solutions-main">
        <div className="container">
          <h1 className="title title--uppercase title--margin-top white-text">
              <span className="title__preheader title__preheader--tag">
                Technologies
              </span>
            SOFTWARE DEVELOPMENT <br />
            WITH IMPACTFUL SOLUTIONS
          </h1>
          <p className="header__lead">
            We are very much aware of the rigorousity that comes with performing
            Process Transformation. Be it the use of AR to enhance a better
            service provision or create an AI/IoT ecosystem with Digitally
            Oriented expertise, we have therefore put in place an ever-growing
            team of (full-stacks) and talented individuals in Mauritius, Kenya
            and in Lagos in order for us to meet the demands of emerging
            technologies of all our respective clients and to provide them with
            first-hand technology solutions
          </p>
        </div>
      </section>

      <section className="tech-solution">
        <div className="container">
          <h2 className="solutions-page__title">
            ENTERPRISE SOFTWARE SOLUTIONS WITH THE LATEST TECHNOLOGY
          </h2>
          <p className="solutions-page__descr">
            From the production floor to the conference room, our disruptive
            ideas are first-hand solutions that will help to drive corporate
            vision and goals in your enterprise. Our team of full-stack
            professionals can help you overcome the challenges of digital
            transformation both in daily, small and large-scale operations.
          </p>

          <div className="tech">
            <div className="tech__item">
              <div className="tech__img-wrapper">
                <img
                  src={require("../img/solutions/blockchain-technology.svg").default}
                  alt="Blockchain Icon"
                  className="tech__img tech__img--blockchain"
                />
              </div>
              <h3 className="tech__title">Blockchain Development</h3>
              <div className="tech__descr-wrapper">
                <a
                  target="_blank"
                  className="tech__link"
                  title="Go to Blockchain Lab"
                >
                  <h3 className="tech__link-title">Blockchain Development</h3>
                  <p className="tech__text">
                  Present the largest level of security and computerize your operations with our blockchain technology solutions.
                  </p>
                  {/*<span className="tech__more">*/}
                  {/*  view more*/}
                  {/*  <i className="isoi-angle-right" />*/}
                  {/*</span>*/}
                </a>
              </div>
            </div>
            <div className="tech__item">
              <div className="tech__img-wrapper">
                <img
                  src={require("../img/solutions/cloud-computing.svg").default}
                  alt="Cloud Computing Icon"
                  className="tech__img"
                />
              </div>
              <h3 className="tech__title">Cloud Computing</h3>
              <div className="tech__descr-wrapper">
                <a
                  className="tech__link"
                >
                  <h3 className="tech__link-title">Cloud Computing</h3>
                  <p className="tech__text">
                  Let our cloud solutions help you create a scalable and flexible enterprise environment that is connected on a faster and projected cloud-base system.
                  </p>
                  {/*<span className="tech__more">*/}
                  {/*  view more*/}
                  {/*  <i className="isoi-angle-right" />*/}
                  {/*</span>*/}
                </a>
              </div>
            </div>
            <div className="tech__item">
              <div className="tech__img-wrapper">
                <img
                  src={require("../img/solutions/augmented-reality.svg").default}
                  alt="Augmented Reality Icon"
                  className="tech__img"
                />
              </div>
              <h3 className="tech__title">Augmented Reality Solutions</h3>
              <div className="tech__descr-wrapper">
                <a
                  className="tech__link"
                  title="Go to AR Lab"
                  target="_blank"
                >
                  <h3 className="tech__link-title">
                    Augmented Reality Solutions
                  </h3>
                  <p className="tech__text">
                  With AR solutions, you can  build a better prototypes that will improve your user experience, and also lower costs effects.
                  </p>
                  {/*<span className="tech__more">*/}
                  {/*  view more*/}
                  {/*  <i className="isoi-angle-right" />*/}
                  {/*</span>*/}
                </a>
              </div>
            </div>
            <div className="tech__item tech__item--iot">
              <div className="tech__img-wrapper">
                <img
                  src={require("../img/solutions/internet-of-things.svg").default}
                  alt="IoT Icon"
                  className="tech__img tech__img--iot"
                />
              </div>
              <h3 className="tech__title">IOT SOLUTIONS</h3>
              <div className="tech__descr-wrapper">
                <a
                  target="_blank"
                  className="tech__link"
                  title="Go to IoT Lab"
                >
                  <h3 className="tech__link-title">IOT SOLUTIONS</h3>
                  <p className="tech__text">
                  Automated security of your IoT solutions wil help you safeguard Big Data " data optimization", key processes, and better your decision-making process
                  </p>
                  {/*<span className="tech__more">*/}
                  {/*  view more*/}
                  {/*  <i className="isoi-angle-right" />*/}
                  {/*</span>*/}
                </a>
              </div>
            </div>
            <div className="tech__item">
              <div className="tech__img-wrapper">
                <img
                  src={require("../img/solutions/artificial-intelligence.svg").default}
                  alt="AI Icon"
                  className="tech__img"
                />
              </div>
              <h3 className="tech__title">AI ENTERPRISE SOLUTIONS</h3>
              <div className="tech__descr-wrapper">
                <a className="tech__link">
                  <h3 className="tech__link-title">AI ENTERPRISE SOLUTIONS</h3>
                  <p className="tech__text">
                  Parsonalized AI user-based solutions such as deep learning and machine (robots) will help to enhance daily business-operations.
                  </p>
                  {/*<span className="tech__more">*/}
                  {/*  view more*/}
                  {/*  <i className="isoi-angle-right" />*/}
                  {/*</span>*/}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="stack-block">
        <div className="container">
          <h2 className="solutions-page__title">
            SOFTWARE DEVELOPMENT PLATFORMS, TECHNOLOGIES &amp; Languages
          </h2>
          <p className="solutions-page__descr">
            Our expertise have over ten years of experience in the ICT field
            thereby bringing you wider-rarray of enterprise software solution in
            all the aspects of your business operations for a first-hand
            application and software development that will work across all
            platforms.
          </p>
          <div className="stack-block__wrapper">
            <div className="stack-block__stack">
              <div className="stack" id={"EnterpriseSoftwareDevelopment"}>
                <h3 className="stack__title">
                  Enterprise Software Development | Big Data | Data Analytics |
                  Cloud Computing
                </h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/aws.png").default}
                      srcSet="../img/solutions/enterprise/aws@2x.png 2x, ../img/solutions/enterprise/aws@3x.png 3x"
                      alt="Amazon Web Services Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Amazon Web Services</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/azure.png").default}
                      srcSet="../img/solutions/enterprise/azure@2x.png 2x, ../img/solutions/enterprise/azure@3x.png 3x"
                      alt="Microsoft Azure Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Microsoft Azure</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/gcp.png").default}
                      srcSet="../img/solutions/enterprise/gcp@2x.png 2x, ../img/solutions/enterprise/gcp@3x.png 3x"
                      alt="Google Cloud Platform Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Google Cloud Platform</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/openstack.png").default}
                      srcSet="../img/solutions/enterprise/openstack@2x.png 2x, ../img/solutions/enterprise/openstack@3x.png 3x"
                      alt="Openstack Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Openstack</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/kubernetes.png").default}
                      srcSet="../img/solutions/enterprise/kubernetes@2x.png 2x, ../img/solutions/enterprise/kubernetes@3x.png 3x"
                      alt="Kubernetes Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Kubernetes</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/mesosphere.png").default}
                      srcSet="../img/solutions/enterprise/mesosphere@2x.png 2x, ..img/solutions/enterprise/mesosphere@3x.png 3x"
                      alt="Mesosphere Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Mesosphere</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/docker.png").default}
                      srcSet="../img/solutions/enterprise/docker@2x.png 2x, ../img/solutions/enterprise/docker@3x.png 3x"
                      alt="Docker Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Docker</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/hadoop.png").default}
                      srcSet="../img/solutions/enterprise/hadoop@2x.png 2x, ../img/solutions/enterprise/hadoop@3x.png 3x"
                      alt="Hadoop Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Hadoop</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/cassandra.png").default}
                      srcSet="../img/solutions/enterprise/cassandra@2x.png 2x, ../img/solutions/enterprise/cassandra@3x.png 3x"
                      alt="Cassandra Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Cassandra</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/java.png").default}
                      srcSet="../img/solutions/enterprise/java@2x.png 2x, ../img/solutions/enterprise/java@3x.png 3x"
                      alt="Java Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Java</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/scala.png").default}
                      srcSet="../img/solutions/enterprise/scala@2x.png 2x, ../img/solutions/enterprise/scala@3x.png 3x"
                      alt="Scala Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Scala</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/c.png").default}
                      srcSet="../img/solutions/enterprise/c@2x.png 2x, ../img/solutions/enterprise/c@3x.png 3x"
                      alt="C++ Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">C++</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/c-sharp.png").default}
                      srcSet="../img/solutions/enterprise/c-sharp@2x.png 2x, ../img/solutions/enterprise/c-sharp@3x.png 3x"
                      alt="C sharp Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">C sharp</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/spring.png").default}
                      srcSet="../img/solutions/enterprise/spring@2x.png 2x, ../img/solutions/enterprise/spring@3x.png 3x"
                      alt="Spring"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Spring</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/net.png").default}
                      srcSet="../img/solutions/enterprise/net@2x.png 2x, ../img/solutions/enterprise/net@3x.png 3x"
                      alt="Microsoft ASP.NET Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Microsoft ASP.NET</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/my-sql.png").default}
                      srcSet="../img/solutions/enterprise/my-sql@2x.png 2x, ../img/solutions/enterprise/my-sql@3x.png 3x"
                      alt="MySQL Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">MySQL</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/oracle.png").default}
                      srcSet="../img/solutions/enterprise/oracle@2x.png 2x, ../img/solutions/enterprise/oracle@3x.png 3x"
                      alt="Oracle Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Oracle</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/postgre-sql.png").default}
                      srcSet="../img/solutions/enterprise/postgre-sql@2x.png 2x, ../img/solutions/enterprise/postgre-sql@3x.png 3x"
                      alt="PostgreSQL Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">PostgreSQL</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/enterprise/ms-sql.png").default}
                      srcSet="../img/solutions/enterprise/ms-sql@2x.png 2x, ../img/solutions/enterprise/ms-sql@3x.png 3x"
                      alt="Microsoft SQL Server Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Microsoft SQL Server</h4>
                </div>
              </div>
              <div className="stack" id={"BlockchainDevelopment"}>
                <h3 className="stack__title">Blockchain Development</h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/blockchain/solidity.png").default}
                      srcSet="../img/solutions/blockchain/solidity@2x.png 2x, ../img/solutions/blockchain/solidity@3x.png 3x"
                      alt="Solidity Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Solidity</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/blockchain/hyperledger.png").default}
                      srcSet="../img/solutions/blockchain/hyperledger@2x.png 2x, ../img/solutions/blockchain/hyperledger@3x.png 3x"
                      alt="Hyperledger Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Hyperledger</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/blockchain/ethereum.png").default}
                      srcSet="../img/solutions/blockchain/ethereum@2x.png 2x, ../img/solutions/blockchain/ethereum@3x.png 3x"
                      alt="Ethereum Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Ethereum</h4>
                </div>
              </div>
              <div className="stack" id={"MobileAppDevelopment"}>
                <h3 className="stack__title">Mobile App Development</h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/i-os.png").default}
                      srcSet="../img/solutions/mobile/i-os@2x.png 2x, ../img/solutions/mobile/i-os@3x.png 3x"
                      alt="iOS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">iOS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/android.png").default}
                      srcSet="../img/solutions/mobile/android@2x.png 2x, ../img/solutions/mobile/android@3x.png 3x"
                      alt="Android Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Android</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/xamarin.png").default}
                      srcSet="../img/solutions/mobile/xamarin@2x.png 2x, ../img/solutions/mobile/xamarin@3x.png 3x"
                      alt="Xamarin Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Xamarin H</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/html.png").default}
                      srcSet="../img/solutions/mobile/html@2x.png 2x, ../img/solutions/mobile/html@3x.png 3x"
                      alt="HTML5 Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">HTML5</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/javascript.png").default}
                      srcSet="../img/solutions/mobile/javascript@2x.png 2x, ../img/solutions/mobile/javascript@3x.png 3x"
                      alt="JavaScript Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">JavaScript</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/mobile/react.png").default}
                      srcSet="../img/solutions/mobile/react@2x.png 2x, ../img/solutions/mobile/react@3x.png 3x"
                      alt="React Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">React</h4>
                </div>
              </div>
            </div>
            <div className="stack-block__stack">
              <div className="stack" id={"WebDevelopment"}>
                <h3 className="stack__title stack__title--web">
                  Web Development
                </h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/symfony.png").default}
                      srcSet="../img/solutions/web/symfony@2x.png 2x, ../img/solutions/web/symfony@3x.png 3x"
                      alt="Symfony Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Symfony</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/ruby.png").default}
                      srcSet="../img/solutions/web/ruby@2x.png 2x, ../img/solutions/web/ruby@3x.png 3x"
                      alt="Ruby Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Ruby</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/python.png").default}
                      srcSet="../img/solutions/web/python@2x.png 2x, ../img/solutions/web/python@3x.png 3x"
                      alt="Python Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Python</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/php.png").default}
                      srcSet="../img/solutions/web/php@2x.png 2x, ../img/solutions/web/php@3x.png 3x"
                      alt="PHP Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">PHP</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/angular.png").default}
                      srcSet="../img/solutions/web/angular@2x.png 2x, ../img/solutions/web/angular@3x.png 3x"
                      alt="Symfony Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">AngularJS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/react.png").default}
                      srcSet="../img/solutions/web/react@2x.png 2x, ../img/solutions/web/react@3x.png 3x"
                      alt="Symfony Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">React</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/js.png").default}
                      srcSet="../img/solutions/web/js@2x.png 2x, ../img/solutions/web/js@3x.png 3x"
                      alt="Symfony Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">JavaScript</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/node.png").default}
                      srcSet="../img/solutions/web/node@2x.png 2x, ../img/solutions/web/node@3x.png 3x"
                      alt="NodeJS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">NodeJS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/web/jquery.png").default}
                      srcSet="../img/solutions/web/jquery@2x.png 2x, ../img/solutions/web/jquery@3x.png 3x"
                      alt="jQuery Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">jQuery</h4>
                </div>
              </div>
              <div className="stack" id={"IoTDevelopment"}>
                <h3 className="stack__title">IoT Development</h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/android-things.png").default}
                      srcSet="../img/solutions/iot/android-things@2x.png 2x, ../img/solutions/iot/android-things@3x.png 3x"
                      alt="Android Things Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Android Things</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/aws-iot.png").default}
                      srcSet="../img/solutions/iot/aws-iot@2x.png 2x, ../img/solutions/iot/aws-iot@3x.png 3x"
                      alt="AWS IoT Things Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">AWS IoT</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/google-cloud-iot.png").default}
                      srcSet="../img/solutions/iot/google-cloud-iot@2x.png 2x, ../img/solutions/iot/google-cloud-iot@3x.png 3x"
                      alt="Google Cloud IoT Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Google Cloud IoT</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/linux.png").default}
                      srcSet="../img/solutions/iot/linux@2x.png 2x, ../img/solutions/iot/linux@3x.png 3x"
                      alt="Embedded Linux Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Embedded Linux</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/android-firmware.png").default}
                      srcSet="../img/solutions/iot/android-firmware@2x.png 2x, ../img/solutions/iot/android-firmware@3x.png 3x"
                      alt="Android Firmware Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Android Firmware</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/android-auto.png").default}
                      srcSet="../img/solutions/iot/android-auto@2x.png 2x, ../img/solutions/iot/android-auto@3x.png 3x"
                      alt="Android Auto Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Android Auto</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/wear-os.png").default}
                      srcSet="../img/solutions/iot/wear-os@2x.png 2x, ../img/solutions/iot/wear-os@3x.png 3x"
                      alt="WearOS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">WearOS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/azure-iot.png").default}
                      srcSet="../img/solutions/iot/azure-iot@2x.png 2x, ../img/solutions/iot/azure-iot@3x.png 3x"
                      alt="Microsoft Azure IoT Suite Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Microsoft Azure IoT Suite</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/samsung-artik.png").default}
                      srcSet="../img/solutions/iot/samsung-artik@2x.png 2x, ../img/solutions/iot/samsung-artik@3x.png 3x"
                      alt="Samsung ARTIK Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Samsung ARTIK</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/pubnub.png").default}
                      srcSet="../img/solutions/iot/pubnub@2x.png 2x, ../img/solutions/iot/pubnub@3x.png 3x"
                      alt="PubNub Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">PubNub</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/riot-os.png").default}
                      srcSet="../img/solutions/iot/riot-os@2x.png 2x, ../img/solutions/iot/riot-os@3x.png 3x"
                      alt="Riot OS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Riot OS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/zephyr.png").default}
                      srcSet="../img/solutions/iot/zephyr@2x.png 2x, ../img/solutions/iot/zephyr@3x.png 3x"
                      alt="Zephyr Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Zephyr</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/contiki.png").default}
                      srcSet="../img/solutions/iot/contiki@2x.png 2x, ../img/solutions/iot/contiki@3x.png 3x"
                      alt="Contiki OS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Contiki OS</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/iot/rtos.png").default}
                      srcSet="../img/solutions/iot/rtos@2x.png 2x, ../img/solutions/iot/rtos@3x.png 3x"
                      alt="Free RTOS Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Free RTOS</h4>
                </div>
              </div>
              <div className="stack" id={"AIDevelopment"}>
                <h3 className="stack__title">
                  Artificial Intelligence/Machine Learning
                </h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/ai/tensor-flow.png").default}
                      srcSet="../img/solutions/ai/tensor-flow@2x.png 2x, ../img/solutions/ai/tensor-flow@3x.png 3x"
                      alt="TensorFlow Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">TensorFlow</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/ai/spark.png").default}
                      srcSet="../img/solutions/ai/spark@2x.png 2x, ../img/solutions/ai/spark@3x.png 3x"
                      alt="Spark Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Spark</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/ai/dl4.png").default}
                      srcSet="../img/solutions/ai/dl4@2x.png 2x, ../img/solutions/ai/dl4@3x.png 3x"
                      alt="DL4J Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">DL4J</h4>
                </div>
              </div>
              <div className="stack" id={"ARandVRDevelopment"}>
                <h3 className="stack__title">AR &amp; VR Development</h3>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/ar/unity.png").default}
                      srcSet="../img/solutions/ar/unity@2x.png 2x, ../img/solutions/ar/unity@3x.png 3x"
                      alt="Unity Icon"
                      className="stack__img"
                    />
                  </div>
                  <h4 className="stack__name">Unity</h4>
                </div>
                <div className="stack__item">
                  <div className="stack__img-wrapper">
                    <img
                      src={require("../img/solutions/ar/web-assembly.png").default}
                      srcSet="../img/solutions/ar/web-assembly@2x.png 2x, ../img/solutions/ar/web-assembly@3x.png 3x"
                      alt="Web Assembly Icon"
                      className="stack__img/"
                    />
                  </div>
                  <h4 className="stack__name">Web Assembly</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Technologies;
