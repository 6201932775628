import React, { Component } from "react";
//import React from 'react';
//import logo from './logo.svg';
import Home from "./pages/Home";
import Client from "./pages/Clients";
import Company from "./pages/Company";
import Industries from "./pages/Industries";
import Technologies from "./pages/Technologies";
import Services from "./pages/Services";
//import Cookies from  './components/Cookies';

import IoT from "./pages/technologies/IoT";
import AR from "./pages/technologies/AR";

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import {
  Route, Routes,
  //NavLink,
  BrowserRouter
} from "react-router-dom";

import "./App.css";
import "./css/style.css";
import "./css/swiper.min.css";
// import Navbar from "./components/nav/Navbar";

//import Home from './Home';

// export default () => {
//   return <Home name="Alligator" />;
// };

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div id="o-wrapper" className="wrapper overflow-hidden">
          <div>
            {/*<Nav />*/}
            <Navbar />
          </div>

          <div className="content">
            <Routes>
              <Route exact path="/" element={<Home/>} />
              <Route path="/services" element={<Services/>} />
              <Route path="/clients" element={<Client/>} />
              <Route path="/technologies" element={<Technologies/>} >
                {/*<Route path="iot" element={<IoT/>} />*/}
                {/*<Route path="ar" element={<AR/>} />*/}
              </Route>
              <Route path="/industries" element={<Industries/>} />
              <Route path="/company" element={<Company/>} />
              <Route path="/contact" element={<Contact/>} />
            </Routes>
          </div>

          <Footer />
        </div>

      </BrowserRouter>
    );
  }
}

export default App;
