import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

// export default class ClientsSection extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             gallerySwiper: null,
//             thumbnailSwiper: null
//         };
//         this.galleryRef = this.galleryRef.bind(this);
//         this.thumbRef = this.thumbRef.bind(this);
//     }
//
//     componentDidUpdate(nextProps, nextState) {
//         if (nextState.gallerySwiper && nextState.thumbnailSwiper) {
//             const {gallerySwiper, thumbnailSwiper} = nextState
//             gallerySwiper.controller.control = thumbnailSwiper;
//             thumbnailSwiper.controller.control = gallerySwiper;
//         }
//     }
//
//     galleryRef(ref) {
//         if (ref) this.setState({gallerySwiper: ref.swiper})
//     }
//
//     thumbRef(ref) {
//         if (ref) this.setState({thumbnailSwiper: ref.swiper})
//     }
//
//     render() {
//         const gallerySwiperParams = {
//             spaceBetween: 10,
//             centeredSlides: true,
//             navigation: {
//                 nextEl: '.swiper-button-next',
//                 prevEl: '.swiper-button-prev',
//             },
//             autoplay: {
//                 delay: 500,
//                 disableOnInteraction: false
//             },
//         };
//
//         const thumbnailSwiperParams = {
//             paceBetween: 10,
//             centeredSlides: false,
//             slidesPerView: 8,
//             touchRatio: 0.2,
//             slideToClickedSlide: true,
//         };
//
//         return(
//             <div id="o-wrapper" className="wrapper overflow-hidden">
//                 <section className="clients-success">
//                     <div className="container">
//                         <div className="offers-title">
//                             <h2 className="title title--black"> Client Success </h2>
//                         </div>
//                         <Swiper {...gallerySwiperParams}  ref={this.galleryRef}>
//                             <div className="slide-wrapper">
//                                 <img className="clients-image lazy-background" src={require('../img/new/eif-logo.png').default}/>
//                                 <div className="content">
//                                     <h3 className="title">EIF </h3>
//                                     <p className="caption">Every Innovate Man, every foreman's project management and tracking tool...</p>
//                                     <a href="https://eif.app/" className="content-link-more">view more<i className="isoi-angle-right"/></a>
//                                 </div>
//                             </div>
//                             <div className="slide-wrapper">
//                                 <img className="clients-image lazy-background" src={require('../img/new/tecslahomes-logo.png').default}/>
//                                 <div className="content">
//                                     <h3 className="title">TecslaHomes</h3>
//                                     <p className="caption">TecslaHomes, homes in Africa...</p>
//                                     <a href="https://tecslahomes.com/" className="content-link-more">
//                                         view more<i className="isoi-angle-right"/>
//                                     </a>
//                                 </div>
//                             </div>
//                         </Swiper>
//                     </div>
//                 </section>
//             </div>
//         )
//     }
// }

const ClientsSection = () => {
    const params = {
        spaceBetween: 10,
        centeredSlides: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        },
    }

    return(
        <div id="o-wrapper" className="wrapper overflow-hidden">
            <section className="clients-success">
                <div className="container">
                    <div className="offers-title">
                        <h2 className="title title--black"> Client Success </h2>
                    </div>
                    <Swiper {...params}>
                        <div className="slide-wrapper">
                            <img className="clients-image lazy-background" src={require('../img/new/2-removebg.png').default}/>
                            <div className="content">
                                <h3 className="title">EIF </h3>
                                <p className="caption">Every Innovative Foreman is a construction management application that helps with the tracking, monitoring; it also organise your files, and much more through out the entire project timeline.</p>
                                <a href="https://eif.app/" className="content-link-more">view more<i className="isoi-angle-right"/></a>
                            </div>
                        </div>
                        <div className="slide-wrapper">
                            <img className="clients-image lazy-background" src={require('../img/new/1-removebg.png').default}/>
                            <div className="content">
                                <h3 className="title">TecslaHomes</h3>
                                <p className="caption">TecslaHomes, homes in Africa...</p>
                                <a href="https://tecslahomes.com/" className="content-link-more">
                                    view more<i className="isoi-angle-right"/>
                                </a>
                            </div>
                        </div>
                    </Swiper>
                </div>
            </section>
        </div>
    )
}

export default ClientsSection;