import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

const Slider= () => {
    return (
        <section>

            <OwlCarousel
                // className="hero-slider hero-slider--nav owl-carousel owl-loaded owl-drag"
                className="hero-slider hero-slider--nav"
                items={1}
                // lazyLoad
                // nav
                dots={false}
                // smartSpeed={600}
                // loop
                id="heroSliderNav">

                <div className="item" >
                    <div className="hero-slider__item hero-slider__item--1">
                        <div className="hero-slider__overlay overlay-40"/>
                        <div className="hero-slider__content">
                            <h1 className="title title--uppercase title--margin-top white-text">
                                Enterprise Software Development </h1>

                            <p className="hero-slider__lead white-text lead-text">
                                Leverage our ten-year expertise to create a solid software foundation for
                                your business. </p>

                            <Link to="/company"
                               // onClick="ga('send', 'event', 'soft_dev', 'click', 'slider');"
                               className="button button--clear button--white">
                                More about us <i className="isoi-angle-right"/>
                            </Link>
                        </div>
                    </div>
                </div>

                {/*<div className="item" >*/}
                {/*    <div className="hero-slider__item hero-slider__item--2">*/}
                {/*        <div className="hero-slider__overlay overlay-40"/>*/}
                {/*        <div className="hero-slider__content">*/}
                {/*            <h1 className="title title--uppercase title--margin-top white-text">*/}
                {/*                Mobile App Development </h1>*/}

                {/*            <p className="hero-slider__lead white-text lead-text">*/}
                {/*                Use our low-code platform and ten-year expertise in mobile app development*/}
                {/*                to create a solid custom app faster than ever before. </p>*/}

                {/*            <a href="/services/mobile-development"*/}
                {/*               onClick="ga('send', 'event', 'mob_dev', 'click', 'slider');"*/}
                {/*               className="button button--clear button--white">*/}
                {/*                Read more <i className="isoi-angle-right"/>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="item" >*/}
                {/*    <div className="hero-slider__item hero-slider__item--3">*/}
                {/*        <div className="hero-slider__content">*/}
                {/*            <h1 className="title title--uppercase title--margin-top white-text">*/}
                {/*                Dedicated Development Teams </h1>*/}

                {/*            <p className="hero-slider__lead white-text lead-text">*/}
                {/*                Hire top tech talent and quickly scale your delivery capacity. Our engineers*/}
                {/*                have the necessary niche skills, deep expertise, and are highly loyal. </p>*/}

                {/*            <a href="/services/team-extension"*/}
                {/*               onClick="ga('send', 'event', 'ded_team', 'click', 'slider');"*/}
                {/*               className="button button--clear button--white">*/}
                {/*                Read more <i className="isoi-angle-right"/>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="item" >*/}
                {/*    <div className="hero-slider__item hero-slider__item--4">*/}
                {/*        <div className="hero-slider__content">*/}
                {/*            <h1*/}
                {/*                className="title title--uppercase title--margin-top white-text">CUSTOM IoT*/}
                {/*                DEVELOPMENT SERVICES</h1>*/}

                {/*            <p className="hero-slider__lead white-text lead-text">*/}
                {/*                Build secure enterprise Internet of Things (IoT) solutions that will help your*/}
                {/*                business gather Big Data, optimise operations, and unlock a new level of*/}
                {/*                efficiency. </p>*/}

                {/*            <a href="/technologies/iot"*/}
                {/*               onClick="ga('send', 'event', 'iot_lab', 'click', 'slider');"*/}
                {/*               className="button button--clear button--white">*/}
                {/*                Read more <i className="isoi-angle-right"></i>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="item" >*/}
                {/*    <div className="hero-slider__item hero-slider__item--5">*/}
                {/*        <div className="hero-slider__content">*/}
                {/*            <h1 className="title title--uppercase title--margin-top white-text">*/}
                {/*                Custom Augmented Reality Solutions </h1>*/}

                {/*            <p className="hero-slider__lead white-text lead-text">*/}
                {/*                Use equally practical and exciting AR solutions to elevate your key*/}
                {/*                operations, improve employee efficiency, and excite your customers. </p>*/}

                {/*            <a href="/technologies/ar"*/}
                {/*               onClick="ga('send', 'event', 'ar_solution', 'click', 'slider');"*/}
                {/*               className="button button--clear button--white">*/}
                {/*                Read more <i className="isoi-angle-right"></i>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="item" >*/}
                {/*    <div className="hero-slider__item hero-slider__item--6">*/}
                {/*        <div className="hero-slider__content">*/}
                {/*            <h1 className="title title--uppercase title--margin-top white-text">*/}
                {/*                Blockchain Development </h1>*/}

                {/*            <p className="hero-slider__lead white-text lead-text">*/}
                {/*                Blockchain consulting, private blockchains, Smart Contracts, identity*/}
                {/*                solutions — we offer you a full suite of blockchain development*/}
                {/*                services. </p>*/}

                {/*            <a href="/solutions/blockchain"*/}
                {/*               onClick="ga('send', 'event', 'blockchain_solution', 'click', 'slider');"*/}
                {/*               className="button button--clear button--white">*/}
                {/*                Read more <i className="isoi-angle-right"></i>*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </OwlCarousel>
        </section>



    )
}

export default Slider