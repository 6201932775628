import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";

const Who = () => {
    return (


        <section id="" className="cemetery">
            <div className="container">
                <div className="cemetery__title">
                    <h2>Who We Are</h2>
                    <p className="description">As an Information Technology and Digital Oriented enterprise, 
                    We offer 'Technology Solutions for Learning Organizations' (Tecsla); Powered by the Latest Technologies.</p>
                </div>
                {/*<OwlCarousel*/}
                {/*className="owl-theme"*/}
                {/*autoplay={true}*/}
                {/*items={6}*/}
                {/*lazyLoad={true}*/}
                {/*id="cemetery"*/}

            {/*>*/}
                    {/*<div className="item ">*/}
                        {/*<div className="cemetery__image item1"*/}
                             {/*data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}
                        {/*<div className="cemetery__image item1" data-src={require('../img/clients-logo/nhs.png')} >*/}
                         {/*</div>*/}

                        {/*<div className="cemetery__image item1" data-src={require('../img/clients-logo/nhs.png')}>*/}
                         {/*</div>*/}

                        {/*<div className="cemetery__image item1" data-src={require('../img/clients-logo/nhs.png')}>*/}

                        {/*</div>*/}

                    {/*</div>*/}
                    {/*<div className="item item2">*/}
                        {/*<div className="cemetery__image item2"*/}
                             {/*data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}
                        {/*<div className="cemetery__image item2" data-src={require('../img/clients-logo/nhs.png')} >*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image item2" data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image item2" data-src={require('../img/clients-logo/nhs.png')}>*/}

                        {/*</div>*/}

                    {/*</div>*/}
                    {/*<div className="item ">*/}
                        {/*<div className="cemetery__image "*/}
                             {/*data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}
                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')} >*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}

                        {/*</div>*/}

                    {/*</div> <div className="item ">*/}
                    {/*<div className="cemetery__image "*/}
                         {/*data-src={require('../img/clients-logo/nhs.png')}>*/}
                    {/*</div>*/}
                    {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')} >*/}
                    {/*</div>*/}

                    {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}
                    {/*</div>*/}

                    {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}

                    {/*</div>*/}

                {/*</div>*/}
                    {/*<div className="item ">*/}
                        {/*<div className="cemetery__image "*/}
                             {/*data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}
                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')} >*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}
                        {/*</div>*/}

                        {/*<div className="cemetery__image " data-src={require('../img/clients-logo/nhs.png')}>*/}

                        {/*</div>*/}

                    {/*</div>*/}

            {/*<div className="item ">*/}
                {/*<div className="cemetery__image "*/}
                     {/*data-src={require('../img/clients-logo/universal.png')}>*/}
                {/*</div>*/}
                {/*<div className="cemetery__image " data-src={require('../img/clients-logo/universal.png')} >*/}
                {/*</div>*/}

                {/*<div className="cemetery__image " data-src={require('../img/clients-logo/universal.png')}>*/}
                {/*</div>*/}

                {/*<div className="cemetery__image " data-src={require('../img/clients-logo/universal.png')}>*/}
                {/*</div>*/}
            {/*</div>*/}
        {/*</OwlCarousel>*/}
                <div className="column column--flex-center p-top-40 pb-20">
                    <Link to="/contact" className="button button--clear button--blue">
                        Talk to our team<i className="isoi-angle-right"/>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Who